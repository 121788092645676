import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';

const App = () => {
  const [welcomeTitle, setWelcomeTitle] = useState('欢迎访问，正在自动匹配最佳线路访问官网');
  const [status, setStatus] = useState('loading'); // 'loading', 'checking', 'success', 'error'
  const [message, setMessage] = useState('正在加载配置...');
  const [progress, setProgress] = useState(0);
  const [bestUrl, setBestUrl] = useState(null);
  const [showBookmarkMessage, setShowBookmarkMessage] = useState(false);
  const [isWechatBrowser, setIsWechatBrowser] = useState(false);
  const redirectDelay = 3000; // 3 seconds delay

  const decodeUrl = useCallback((encodedUrl) => {
    try {
      return atob(encodedUrl);
    } catch (e) {
      return encodedUrl;
    }
  }, []);

  const checkUrl = useCallback(async (encodedUrl) => {
    const decodedUrl = decodeUrl(encodedUrl);
    try {
      await axios.get(decodedUrl, { timeout: 5000 });
      return decodedUrl;
    } catch (error) {
      return null;
    }
  }, [decodeUrl]);

  const checkUrlsAndRedirect = useCallback(async (urls) => {
    setStatus('checking');
    setMessage('正在检查可用线路...');

    for (let i = 0; i < urls.length; i++) {
      setProgress(((i + 1) / urls.length) * 100);
      const accessibleUrl = await checkUrl(urls[i]);
      if (accessibleUrl) {
        setBestUrl(accessibleUrl);
        setStatus('success');
        setMessage(`已找到最佳线路，请等待浏览器自动跳转！`);
        return;
      }
    }

    setStatus('error');
    setMessage('暂无可用线路，请稍后再试');
  }, [checkUrl]);

  const detectWechatBrowser = useCallback(() => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1;
  }, []);

  useEffect(() => {
    setIsWechatBrowser(detectWechatBrowser());

    if (!detectWechatBrowser()) {
      const loadConfig = async () => {
        try {
          const response = await axios.get('/config.json');
          const config = response.data;
          setWelcomeTitle(config.welcomeTitle || welcomeTitle);
          
          if (config.urls && config.urls.length > 0) {
            await checkUrlsAndRedirect(config.urls);
          } else {
            setStatus('error');
            setMessage('没有可用的 URL，请联系管理员');
          }
        } catch (error) {
          console.error('加载配置失败', error);
          setStatus('error');
          setMessage('加载配置失败，请刷新页面重试');
        }
      };

      loadConfig();
    }
  }, [checkUrlsAndRedirect, welcomeTitle, detectWechatBrowser]);

  useEffect(() => {
    if (status === 'success' && bestUrl && !isWechatBrowser) {
      const timer = setTimeout(() => {
        window.location.href = bestUrl;
      }, redirectDelay);
      return () => clearTimeout(timer);
    }
  }, [status, bestUrl, redirectDelay, isWechatBrowser]);

  const handleBookmarkClick = () => {
    if (window.sidebar && window.sidebar.addPanel) { // Firefox < 23
      window.sidebar.addPanel(document.title, window.location.href, '');
    } else if (window.external && ('AddFavorite' in window.external)) { // IE
      window.external.AddFavorite(window.location.href, document.title);
    } else { // Chrome, Safari, Firefox 23+, Opera 15+ etc.
      setShowBookmarkMessage(true);
    }
  };

  const handleCopyLink = () => {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = window.location.href;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    alert('链接已复制到剪贴板');
  };

  if (isWechatBrowser) {
    return (
      <div className="app wechat-warning">
        <h1>请勿在微信内打开</h1>
        <p>为了更好的体验，请复制链接并在浏览器中打开</p>
        <button onClick={handleCopyLink} className="copy-link-button">
          复制链接
        </button>
      </div>
    );
  }

  return (
    <div className="app">
      <h1>{welcomeTitle}</h1>
      <div className="bookmark-reminder">
        请收藏此页防止失联
      </div>
      <button onClick={handleBookmarkClick} className="bookmark-button">
        <span className="bookmark-icon">⭐</span> 添加到收藏夹
      </button>
      {showBookmarkMessage && (
        <div className="bookmark-message">
          请使用您的浏览器的收藏功能来收藏此页面
          <br />
          (Windows 通常是 Ctrl+D, Mac 通常是 Command+D)
        </div>
      )}
      {status === 'checking' && (
        <div className="progress-container">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      <h2>{message}</h2>
    </div>
  );
};

export default App;